import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;
const PAGE_SIZE = 100;

export const useRequestApiAlarmReceivingGroupRegister = () => {
    const requestAxioApiAlarmReceivingGroupRegister = async (data: TypeDTO.AlarmReceivingGroupDto) => {
        const response = await utilAxiosWithAuth().post(Request.ALARM_RECEIVING_GROUP_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiAlarmReceivingGroupRegister,
        error: errorApiAlarmReceivingGroupRegister,
        data: resultApiAlarmReceivingGroupRegister,
        execute: requestApiAlarmReceivingGroupRegister,
    } = useAsyncAxios(requestAxioApiAlarmReceivingGroupRegister);

    useEffect(() => {
        if (resultApiAlarmReceivingGroupRegister === null) return;
        DEBUG && console.log("resultApiAlarmReceivingGroupRegister", resultApiAlarmReceivingGroupRegister);
    }, [resultApiAlarmReceivingGroupRegister]);

    useEffect(() => {
        if (errorApiAlarmReceivingGroupRegister === null) return;
        DEBUG && console.log("errorApiAlarmReceivingGroupRegister", errorApiAlarmReceivingGroupRegister);
    }, [errorApiAlarmReceivingGroupRegister]);

    return {
        loadingApiAlarmReceivingGroupRegister,
        errorApiAlarmReceivingGroupRegister,
        resultApiAlarmReceivingGroupRegister,
        requestApiAlarmReceivingGroupRegister,
    };
};

export const useRequestApiAlarmReceivingGroupList = () => {
    const requestAxioApiAlarmReceivingGroupList = async () => {
        const response = await utilAxiosWithAuth().get(Request.ALARM_RECEIVING_GROUPS_URL);

        return response.data;
    };

    const {
        loading: loadingApiAlarmReceivingGroupList,
        error: errorApiAlarmReceivingGroupList,
        data: resultApiAlarmReceivingGroupList,
        execute: requestApiAlarmReceivingGroupList,
    } = useAsyncAxios(requestAxioApiAlarmReceivingGroupList);

    useEffect(() => {
        if (resultApiAlarmReceivingGroupList === null) return;
        DEBUG && console.log("resultApiAlarmReceivingGroupList", resultApiAlarmReceivingGroupList);
    }, [resultApiAlarmReceivingGroupList]);

    useEffect(() => {
        if (errorApiAlarmReceivingGroupList === null) return;
        DEBUG && console.log("errorApiAlarmReceivingGroupList", errorApiAlarmReceivingGroupList);
    }, [errorApiAlarmReceivingGroupList]);

    return {
        loadingApiAlarmReceivingGroupList,
        errorApiAlarmReceivingGroupList,
        resultApiAlarmReceivingGroupList,
        requestApiAlarmReceivingGroupList,
    };
};

export const useRequestApiAlarmReceivingGroupInfo = () => {
    const requestAxioApiAlarmReceivingGroupInfo = async (alarmReceivingGroupId: number) => {
        const response = await utilAxiosWithAuth().get(Request.ALARM_RECEIVING_GROUP_URL + alarmReceivingGroupId);

        return response.data;
    };

    const {
        loading: loadingApiAlarmReceivingGroupInfo,
        error: errorApiAlarmReceivingGroupInfo,
        data: resultApiAlarmReceivingGroupInfo,
        execute: requestApiAlarmReceivingGroupInfo,
    } = useAsyncAxios(requestAxioApiAlarmReceivingGroupInfo);

    useEffect(() => {
        if (resultApiAlarmReceivingGroupInfo === null) return;
        DEBUG && console.log("resultApiAlarmReceivingGroupInfo", resultApiAlarmReceivingGroupInfo);
    }, [resultApiAlarmReceivingGroupInfo]);

    useEffect(() => {
        if (errorApiAlarmReceivingGroupInfo === null) return;
        DEBUG && console.log("errorApiAlarmReceivingGroupInfo", errorApiAlarmReceivingGroupInfo);
    }, [errorApiAlarmReceivingGroupInfo]);

    return {
        loadingApiAlarmReceivingGroupInfo,
        errorApiAlarmReceivingGroupInfo,
        resultApiAlarmReceivingGroupInfo,
        requestApiAlarmReceivingGroupInfo,
    };
};

export const useRequestApiAlarmReceivingGroupUpdate = () => {
    const requestAxioApiAlarmReceivingGroupUpdate = async (alarmReceivingGroupId: number, data: TypeDTO.AlarmReceivingGroupDto) => {
        const response = await utilAxiosWithAuth().put(Request.ALARM_RECEIVING_GROUP_URL + alarmReceivingGroupId, data);

        return response.data;
    };

    const {
        loading: loadingApiAlarmReceivingGroupUpdate,
        error: errorApiAlarmReceivingGroupUpdate,
        data: resultApiAlarmReceivingGroupUpdate,
        execute: requestApiAlarmReceivingGroupUpdate,
    } = useAsyncAxios(requestAxioApiAlarmReceivingGroupUpdate);

    useEffect(() => {
        if (resultApiAlarmReceivingGroupUpdate === null) return;
        DEBUG && console.log("resultApiAlarmReceivingGroupUpdate", resultApiAlarmReceivingGroupUpdate);
    }, [resultApiAlarmReceivingGroupUpdate]);

    useEffect(() => {
        if (errorApiAlarmReceivingGroupUpdate === null) return;
        DEBUG && console.log("errorApiAlarmReceivingGroupUpdate", errorApiAlarmReceivingGroupUpdate);
    }, [errorApiAlarmReceivingGroupUpdate]);

    return {
        loadingApiAlarmReceivingGroupUpdate,
        errorApiAlarmReceivingGroupUpdate,
        resultApiAlarmReceivingGroupUpdate,
        requestApiAlarmReceivingGroupUpdate,
    };
};

export const useRequestApiAlarmReceivingGroupDelete = () => {
    const requestAxioApiAlarmReceivingGroupDelete = async (alarmReceivingGroupId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.ALARM_RECEIVING_GROUP_URL + alarmReceivingGroupId);

        return response.data;
    };

    const {
        loading: loadingApiAlarmReceivingGroupDelete,
        error: errorApiAlarmReceivingGroupDelete,
        data: resultApiAlarmReceivingGroupDelete,
        execute: requestApiAlarmReceivingGroupDelete,
    } = useAsyncAxios(requestAxioApiAlarmReceivingGroupDelete);

    useEffect(() => {
        if (resultApiAlarmReceivingGroupDelete === null) return;
        DEBUG && console.log("resultApiAlarmReceivingGroupDelete", resultApiAlarmReceivingGroupDelete);
    }, [resultApiAlarmReceivingGroupDelete]);

    useEffect(() => {
        if (errorApiAlarmReceivingGroupDelete === null) return;
        DEBUG && console.log("errorApiAlarmReceivingGroupDelete", errorApiAlarmReceivingGroupDelete);
    }, [errorApiAlarmReceivingGroupDelete]);

    return {
        loadingApiAlarmReceivingGroupDelete,
        errorApiAlarmReceivingGroupDelete,
        resultApiAlarmReceivingGroupDelete,
        requestApiAlarmReceivingGroupDelete,
    };
};

export type AlarmSearchType = {
    carNumber?: string;
    makerName?: string;
    categoryName?: string;
    startDate?: string;
    endDate?: string;
    error?: boolean;
    warning?: boolean;
    red?: boolean;
    yellow?: boolean;
    blue?: boolean;
};

export const useRequestApiAlarmList = () => {
    const requestAxiosApiAlarmList = async (searchType: AlarmSearchType, page: number, size: number) => {
        const response = await utilAxiosWithAuth().get(Request.ALARMS_URL, {
            params: { ...searchType, page: page, size: PAGE_SIZE },
        });

        return response.data;
    };

    const {
        loading: loadingApiAlarmList,
        error: errorApiAlarmList,
        data: resultApiAlarmList,
        execute: requestApiAlarmList,
    } = useAsyncAxios(requestAxiosApiAlarmList);

    useEffect(() => {
        if (resultApiAlarmList === null) return;
        DEBUG && console.log("resultApiAlarmList", resultApiAlarmList);
    }, [resultApiAlarmList]);

    useEffect(() => {
        if (errorApiAlarmList === null) return;
        DEBUG && console.log("errorApiAlarmList", errorApiAlarmList);
    }, [errorApiAlarmList]);

    return {
        loadingApiAlarmList,
        errorApiAlarmList,
        resultApiAlarmList,
        requestApiAlarmList,
    };
};

export const useRequestApiAlarm = () => {
    const requestAxiosApiAlarm = async (alarmId: number) => {
        const response = await utilAxiosWithAuth().get(Request.ALARM_URL + alarmId);

        return response.data;
    };

    const { loading: loadingApiAlarm, error: errorApiAlarm, data: resultApiAlarm, execute: requestApiAlarm } = useAsyncAxios(requestAxiosApiAlarm);

    useEffect(() => {
        if (resultApiAlarm === null) return;
        DEBUG && console.log("resultApiAlarm", resultApiAlarm);
    }, [resultApiAlarm]);

    useEffect(() => {
        if (errorApiAlarm === null) return;
        DEBUG && console.log("errorApiAlarm", errorApiAlarm);
    }, [errorApiAlarm]);

    return {
        loadingApiAlarm,
        errorApiAlarm,
        resultApiAlarm,
        requestApiAlarm,
    };
};

export const useRequestApiAlarmDownload = () => {
    const requestAxiosApiAlarmDownload = async (searchType: AlarmSearchType) => {
        const response = await utilAxiosWithAuth().get(Request.ALARMS_DOWNLOAD_URL, {
            params: searchType,
        });

        return response.data;
    };

    const {
        loading: loadingApiAlarmDownload,
        error: errorApiAlarmDownload,
        data: resultApiAlarmDownload,
        execute: requestApiAlarmDownload,
    } = useAsyncAxios(requestAxiosApiAlarmDownload);

    useEffect(() => {
        if (resultApiAlarmDownload === null) return;
        DEBUG && console.log("resultApiAlarmDownload", resultApiAlarmDownload);

        // const file = new Blob(["\ufeff" + resultApiAlarmDownload.data], { type: "text/csv;charset=utf-8;" });
        // const element = document.createElement("a");
        // element.href = URL.createObjectURL(file);
        // element.download = decodeURIComponent(resultApiAlarmDownload.headers["content-disposition"].split("filename=")[1].replace(/"/g, ""));
        // element.click();
    }, [resultApiAlarmDownload]);

    useEffect(() => {
        if (errorApiAlarmDownload === null) return;
        DEBUG && console.log("errorApiAlarmDownload", errorApiAlarmDownload);
    }, [errorApiAlarmDownload]);

    return {
        loadingApiAlarmDownload,
        errorApiAlarmDownload,
        resultApiAlarmDownload,
        requestApiAlarmDownload,
    };
};
