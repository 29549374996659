import { useEffect, useState } from "react";
import { useAuthState } from "../../provider/AuthProvider";
import { RadioChangeEvent } from "antd/es/radio/interface";
import { CheckboxValueType } from "antd/es/checkbox/Group";

import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as RequestUser from "../../utils/requestAuthUser";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestCategory from "../../utils/requestApiCategory";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestCustomerManager from "../../utils/requestAuthCustomerManager";
import * as RequestCustomer from "../../utils/requestAuthCustomer";

import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Button from "antd/es/button/button";
import Input from "antd/es/input/index";
import Checkbox from "antd/es/checkbox/index";
import Radio from "antd/es/radio/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import RedoOutlined from "@ant-design/icons/lib/icons/RedoOutlined";

import produce from "immer";
import CustomDatePicker from "../common/CustomDatePicker";
import styles from "./Search.module.css";

import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconYellowExclamtion from "../../assets/images/icon/icon_yellow_exclamation.png";
import IconBlueExclamtion from "../../assets/images/icon/icon_blue_exclamation.png";
import IconNormal from "../../assets/images/icon/icon_gray_check.png";
import IconRedExclamtion from "../../assets/images/icon/icon_red_exclamation.png";
import IconInAction from "../../assets/images/icon/icon_in_action.png";

import moment from "moment";

type summaryType = {
    content: string;
    icon: string;
};

const Search = ({
    title,
    dataLength,
    summary,
    values,
    searchBtn,
    onSearch,
    onClear,
    onClick,
}: {
    title: string;
    dataLength?: number;
    summary?: Array<summaryType>;
    values?: Array<TypeUtils.SearchItemType>;
    searchBtn?: boolean;
    onSearch: (value: any) => void;
    onClear?: (value: any) => void;
    onClick?: () => void;
}) => {
    const { Option } = Select;
    const userDetails = useAuthState();

    const [datas, setDatas] = useState<any>({});
    const [users, setUsers] = useState<Array<TypeDTO.UserDto>>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [categories, setCategories] = useState<Array<TypeDTO.CategoryDto>>();
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [customerManagers, setCustomerManagers] = useState<Array<TypeDTO.CustomerManagerDto>>();
    const [endDatePickerOpen, setEndDatePickerOpen] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);
    const [selectedCustomerManagerName, setSelectedCustomerManagerName] = useState<string>();
    const [selectedMakerName, setSelectedMakerName] = useState<string>();

    useEffect(() => {
        if (!values) return;

        onChangeDefaultDate("search");

        const carNumber = values.find((value) => value.id === "carNumber")?.defaultValue as string;
        const idNumber = values.find((value) => value.id === "idNumber")?.defaultValue as string;
        const manager = values.find((value) => value.id === "customerManagerName")?.defaultValue as string;
        const customer = values.find((value) => value.id === "customerName")?.defaultValue as string;
        const maker = values.find((value) => value.id === "makerName")?.defaultValue as string;
        const model = values.find((value) => value.id === "makerModelId")?.defaultValue as number;
        const category = values.find((value) => value.id === "categoryName")?.defaultValue as string;
        const searchValue = values.find((value) => value.id === "searchValue")?.defaultValue as string;
        const issueManagerName = values.find((value) => value.id === "issueManagerName")?.defaultValue as string;
        const issueType = values.find((value) => value.id === "issueType")?.defaultValue as string;
        const groupName = values.find((value) => value.id === "groupName")?.defaultValue as string;

        setSelectedCustomerManagerName(manager);
        setSelectedMakerName(maker);

        setDatas(
            produce((draft: any) => {
                draft.carNumber = carNumber;
                if (idNumber) draft.idNumber = idNumber;
                if (manager) draft.customerManagerName = manager;
                if (customer) draft.customerName = customer;
                if (maker) draft.makerName = maker;
                if (model) draft.makerModelId = model;
                if (category) draft.categoryName = category;
                if (searchValue) draft.searchValue = searchValue;
                if (issueManagerName) draft.issueManagerName = issueManagerName;
                if (issueType) draft.issueType = issueType;
                if (groupName) draft.groupName = groupName;
            })
        );

        Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]);

        const requests = [
            { typeDetail: "user", action: requestAuthUserList, isCheck: true },
            { typeDetail: "name", action: requestAuthUserList, isCheck: true },
            { typeDetail: "car", action: requestApiCarList, isCheck: true },
            { typeDetail: "idNumber", action: requestApiCarList, isCheck: true },
            { typeDetail: "category", action: requestApiCategoryList, isCheck: true },
            { typeDetail: "maker", action: requestApiMakerList, isCheck: true },
            { typeDetail: "model", action: requestApiMakerList, isCheck: true },
            {
                typeDetail: "customerManager",
                action: requestAuthCustomerManagerList,
                isCheck: Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]),
            },
            {
                typeDetail: "customer",
                action: requestAuthCustomerList,
                isCheck: Utils.roleCheck(userDetails?.user?.authority, [
                    Utils.getAuthority.ROLE_ADMIN,
                    Utils.getAuthority.ROLE_MANAGER,
                    Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                ]),
            },
        ];

        values.forEach(({ typeDetail }) => {
            const request = requests.find((req) => req.typeDetail === typeDetail && req.isCheck);
            if (request) request.action();
        });
    }, [JSON.stringify(values)]);

    useEffect(() => {
        if (Object.keys(datas).length > 0) {
            onSearch(datas);
        }
    }, [datas]);

    const onChangeDefaultDate = (type: "search" | "clear") => {
        const filtedSelectData = values.find((value) => value.type === "select");
        const filtedData = values.find((value) => value.type === "rangePicker");
        const filtedCheckboxData = values.find((value) => value.type === "checkbox");
        const filtedCheckbox2Data = values.find((value) => value.type === "checkbox2");
        const filtedRadioData = values.find((value) => value.type === "radio");
        let defaultData = {};

        if (filtedSelectData && filtedSelectData.defaultValue) {
            if (filtedSelectData.id === "carNumber") {
                defaultData = { ...defaultData, carNumber: filtedSelectData.defaultValue };
            }
        }

        if (filtedData) {
            if (filtedData.defaultStartDate !== "empty") {
                defaultData = {
                    ...defaultData,
                    startDate:
                        filtedData.defaultStartDate ||
                        moment()
                            .add(-7, "days")
                            .format(filtedData.dateFormat || Common.FORMAT_DATE),
                };
            }

            if (filtedData.defaultEndDate !== "empty") {
                defaultData = {
                    ...defaultData,
                    endDate: filtedData.defaultEndDate || moment().format(filtedData.dateFormat || Common.FORMAT_DATE),
                };
            }

            // if (filtedData.defaultStartDate !== "empty" && filtedData.defaultEndDate !== "empty") {
            //     defaultData = {
            //         startDate:
            //             filtedData.defaultStartDate ||
            //             moment()
            //                 .add(-7, "days")
            //                 .format(filtedData.dateFormat || Common.FORMAT_DATE),
            //         endDate: filtedData.defaultEndDate || moment().format(filtedData.dateFormat || Common.FORMAT_DATE),
            //     };
            // }
        }

        if (filtedCheckboxData) {
            defaultData = {
                ...defaultData,
                checkbox: filtedCheckboxData.defaultValue,
            };
        }

        if (filtedCheckbox2Data) {
            defaultData = {
                ...defaultData,
                checkbox2: filtedCheckbox2Data.defaultValue,
            };
        }

        if (filtedRadioData) {
            defaultData = {
                ...defaultData,
                radio: filtedRadioData.defaultValue,
            };
        }

        setDatas(defaultData);
    };

    const displayCheckbox = (value: string) => {
        switch (value) {
            case "error":
                return IconError;
            case "warning":
                return IconYellowExclamtion;
            case "normal":
                return IconNormal;

            case "batteryWarning":
                return IconRedExclamtion;
            case "batteryNormal":
                return IconNormal;

            case "waiting":
                return IconRedExclamtion;
            case "inAction":
                return IconInAction;
            case "completed":
                return IconNormal;

            case "red":
                return IconRedExclamtion;
            case "yellow":
                return IconYellowExclamtion;
            case "blue":
                return IconBlueExclamtion;

            default:
                break;
        }
    };

    const getType = (
        id: string,
        title: string,
        type: string,
        typeDetail?: string,
        options?: any,
        defaultValue?: any,
        dateFormat?: string,
        hasTime?: boolean,
        required?: boolean,
        searchPeriod?: TypeUtils.searchPeriodType
    ) => {
        switch (type) {
            case "input":
                return (
                    <Input className={styles.searchInput} value={datas[id]} placeholder={title} onChange={(e) => onChangeEvent(id, e.target.value)} />
                );
            case "select":
                return (
                    <Select
                        className={styles.searchSelect}
                        defaultValue={defaultValue}
                        value={datas[id]}
                        placeholder={title}
                        onChange={(value) => onChangeEvent(id, value)}
                        allowClear={required === true ? false : true}
                        showSearch
                        optionFilterProp="children"
                        disabled={
                            (typeDetail === "customerManager" &&
                                !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER])) ||
                            (typeDetail === "customer" &&
                                !Utils.roleCheck(userDetails?.user?.authority, [
                                    Utils.getAuthority.ROLE_ADMIN,
                                    Utils.getAuthority.ROLE_MANAGER,
                                    Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                ]))
                        }
                    >
                        {((typeDetail && getSelect(typeDetail)) || options)?.map((option: { value: string; label: string }) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                );
            case "rangePicker":
                return (
                    <Space className={styles.searchDatePicker} size={6}>
                        <CustomDatePicker
                            value={datas.startDate}
                            defaultValue={defaultValue?.startDate}
                            hasTime={hasTime || false}
                            dateFormat={Common.FORMAT_DATE_TIME}
                            allowClear={false}
                            onChange={(value: string | undefined) => {
                                onChangeEvent(
                                    "startDate",
                                    value ? moment(value).format(dateFormat || Common.FORMAT_DATE_HOUR_TIME_START) : undefined
                                );
                            }}
                        />
                        <span>~</span>
                        <CustomDatePicker
                            value={datas.endDate}
                            defaultValue={defaultValue?.endDate}
                            hasTime={hasTime || false}
                            dateFormat={Common.FORMAT_DATE_TIME}
                            allowClear={false}
                            open={endDatePickerOpen}
                            onChange={(value: string | undefined) => {
                                if (
                                    datas.startDate &&
                                    moment(datas.startDate)
                                        .add(searchPeriod?.amount || 1, searchPeriod?.unit || "months")
                                        .isBefore(moment(value))
                                ) {
                                    setEndDateError(true);
                                } else {
                                    setEndDateError(false);
                                    setEndDatePickerOpen(false);
                                    onChangeEvent(
                                        "endDate",
                                        value ? moment(value).format(dateFormat || Common.FORMAT_DATE_HOUR_TIME_END) : undefined
                                    );
                                }
                            }}
                            onClick={() => setEndDatePickerOpen(true)}
                            renderExtraFooter={() =>
                                endDateError && (
                                    <span style={{ color: "var(--danger)" }}>{searchPeriod?.message || "최대 한 달 까지만 선택 가능합니다."}</span>
                                )
                            }
                        />
                    </Space>
                );
            case "checkbox":
            case "checkbox2":
                return (
                    <Checkbox.Group
                        className={styles.checkboxWrapper}
                        onChange={(value: CheckboxValueType[]) => onChangeEvent(id, value)}
                        defaultValue={defaultValue}
                        value={datas[id]}
                    >
                        {options.map((option: any) => {
                            return (
                                <Checkbox key={option.value} className={displayCheckbox(option.value) ? "iconCheckbox" : ""} value={option.value}>
                                    <img alt="icon" src={displayCheckbox(option.value)} width={20} height={20} />
                                    <span className="fw-bd fs-md fc-font1">{option.label}</span>
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                );
            case "radio":
                return (
                    <Radio.Group
                        className={styles.checkboxWrapper}
                        options={options}
                        onChange={(e: RadioChangeEvent) => onChangeEvent(id, e.target.value)}
                        defaultValue={defaultValue}
                        value={datas[id]}
                    />
                );
            default:
                return;
        }
    };

    const getSelect = (value: string) => {
        switch (value) {
            case "user":
                return users?.map((user) => ({ value: user.username, label: user.name }));
            case "name":
                return users?.map((user) => ({ value: user.name, label: user.name }));
            case "car":
                return cars?.map((car) => ({ value: car.carNumber, label: car.carNumber }));
            case "idNumber":
                return cars?.map((car) => ({ value: car.idNumber, label: car.idNumber }));
            case "maker":
                return makers?.map((maker) => ({ value: maker.makerName, label: maker.makerName }));
            case "model":
                return (
                    selectedMakerName
                        ? makers?.find((maker) => maker.makerName === selectedMakerName)?.makerModels
                        : makers?.flatMap((maker) => maker.makerModels)
                )?.map((model) => ({
                    value: model.makerModelId,
                    label: model.makerModelName,
                }));
            case "category":
                return categories?.map((category) => ({ value: category.categoryName, label: category.categoryName }));
            case "customerManager":
                return customerManagers?.map((customerManager) => ({
                    value: customerManager.customerManagerName,
                    label: customerManager.customerManagerName,
                }));
            case "customer":
                return (
                    selectedCustomerManagerName
                        ? customers?.filter((customer) => customer?.customerManager?.customerManagerName === selectedCustomerManagerName)
                        : customers
                )?.map((customer) => ({ value: customer.customerName, label: customer.customerName }));
            default:
                return undefined;
        }
    };

    const onChangeEvent = (type: string, value?: any) => {
        setDatas(
            produce((draft: any) => {
                if (type === "makerName" && value !== selectedMakerName) {
                    setSelectedMakerName(value);
                    draft.makerModelId = undefined;
                } else if (type === "customerManagerName" && value !== selectedCustomerManagerName) {
                    setSelectedCustomerManagerName(value);
                    draft.customerName = undefined;
                }

                if (value === undefined || value === "") {
                    delete draft[type as keyof any];
                } else {
                    draft[type as keyof any] = value;
                }
            })
        );
    };

    const { requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setUsers(resultAuthUserList.users);
    }, [resultAuthUserList]);

    const { requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const { requestApiCategoryList, resultApiCategoryList } = RequestCategory.useRequestApiCategoryList();

    useEffect(() => {
        if (!resultApiCategoryList) return;

        setCategories(resultApiCategoryList.categories);
    }, [resultApiCategoryList]);

    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const { requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { requestAuthCustomerManagerList, resultAuthCustomerManagerList } = RequestCustomerManager.useRequestAuthCustomerManagerList();

    useEffect(() => {
        if (!resultAuthCustomerManagerList) return;

        setCustomerManagers(resultAuthCustomerManagerList.customerManagers);
    }, [resultAuthCustomerManagerList]);

    return (
        <div className={styles.searchWrapper}>
            <Row className="titleWrapper" justify="space-between">
                <Space align="center">
                    <h4 style={{ margin: 0 }}>{title}</h4>
                    {(dataLength || summary) && (
                        <Space size={4}>
                            {dataLength ? <span className="fw-rg fs-sm fc-font2">총 {dataLength}건</span> : ""}
                            {summary && (
                                <Row className="fw-rg fs-sm fc-font2" align="middle">
                                    <span className="fw-rg fs-sm fc-font2">{"("}</span>
                                    <Row className="fw-rg fs-sm fc-font2" align="middle" style={{ gap: 2 }}>
                                        {summary.map((item) => {
                                            return (
                                                <>
                                                    <img alt="" width={16} height={16} src={item.icon} />
                                                    <span className="fw-rg fs-sm fc-font2">{item.content}</span>
                                                </>
                                            );
                                        })}
                                    </Row>
                                    <span className="fw-rg fs-sm fc-font2">{")"}</span>
                                </Row>
                            )}
                        </Space>
                    )}
                </Space>
                <Space size={16}>
                    {onClear && (
                        <span
                            className="fw-bd fs-md fc-font2 btn-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                onChangeDefaultDate("clear");
                            }}
                        >
                            <RedoOutlined />
                            {String.reset}
                        </span>
                    )}
                    {onClick && (
                        <Button className="btn-primary" onClick={() => onClick()}>
                            {!searchBtn ? String.register : title === "기간별 조회" ? "조회" : String.search}
                        </Button>
                    )}
                </Space>
            </Row>
            <Row className={styles.searchFormWrapper}>
                {values?.map((value) => (
                    <Col className={styles.searchForm} key={value.id} span={value.span}>
                        <span className="fw-bd fs-md fc-font2">{value.title}</span>
                        {getType(
                            value.id,
                            value.title,
                            value.type,
                            value.typeDetail,
                            value.options,
                            value.defaultValue,
                            value.dateFormat,
                            value.hasTime,
                            value.required,
                            value.searchPeriod
                        )}
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Search;
