import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { TableProps } from "antd/es/table/InternalTable";
import { useAuthState } from "../../provider/AuthProvider";
import { useSelector, useDispatch } from "react-redux";
import { actionSetIssues } from "../../utils/action";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestIssue from "../../utils/requestApiIssue";

import moment from "moment";
import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import IconRedExclamtion from "../../assets/images/icon/icon_red_exclamation.png";
import IconInAction from "../../assets/images/icon/icon_in_action.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

export type SearchType = {
    carNumber: string;
    makerName: string;
    categoryName: string;
    idNumber: string;
    customerName: string;
    customerManagerName: string;
};

export type CarIssueDto = {
    car: TypeDTO.CarDto;
    issueLevel: "RED" | "YELLOW" | "BLUE";
    actionStatus: "WAITING" | "IN_ACTION" | "COMPLETED";
    lastIssueTime: string;
    count: number;
    issues: Array<TypeDTO.IssueDto>;
};

const PageMaintenanceByCar = () => {
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reducerIssues = useSelector((state) => state.issues);

    const [selectedManagerName, setSelectedManagerName] = useState<string>();
    const [issues, setIssues] = useState<Array<CarIssueDto>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<CarIssueDto>>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [pageSize, setPageSize] = useState<number>(20);
    const [waitingCount, setWaitingCount] = useState<number>();
    const [inActionCount, setInActionCount] = useState<number>();

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
        {
            id: "makerName",
            span: 6,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            span: 6,
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "idNumber",
            span: 12,
            title: String.identityNumber,
            type: "select",
            typeDetail: "idNumber",
        },
        {
            id: "customerManagerName",
            span: 6,
            title: String.customerManager,
            type: "select",
            typeDetail: "customerManager",
        },
        {
            id: "customerName",
            span: 6,
            title: String.customer,
            type: "select",
            typeDetail: "customer",
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<CarIssueDto> = [
        {
            title: String.actionStatus,
            dataIndex: "actionStatus",
            key: "actionStatus",
            align: "center",
            width: "8%",
            render: (_, { actionStatus }) => {
                return actionStatus === "WAITING" ? (
                    <img alt="icon" src={IconRedExclamtion} width={20} height={20} />
                ) : actionStatus === "COMPLETED" ? (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                ) : actionStatus === "IN_ACTION" ? (
                    <img alt="icon" src={IconInAction} width={20} height={20} />
                ) : (
                    String.dash
                );
            },
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            align: "center",
            width: "10%",
        },
        {
            title: String.identityNumber,
            dataIndex: ["car", "idNumber"],
            key: "idNumber",
            align: "center",
            width: "15%",
        },
        {
            title: String.issueCount,
            dataIndex: "count",
            key: "count",
            align: "center",
            width: "10%",
            render: (_, row) => row?.count || 0,
        },
        {
            title: String.maker,
            dataIndex: ["car", "makerModel", "maker", "makerName"],
            key: "maker",
            align: "center",
            width: "10%",
            render: (_, { car }) => car?.makerModel?.maker?.makerName || String.dash,
        },
        {
            title: String.model,
            dataIndex: ["car", "makerModel", "makerModelName"],
            key: "model",
            align: "center",
            width: "10%",
            render: (_, { car }) => car?.makerModel?.makerModelName || String.dash,
        },
        {
            title: String.receiptDate,
            dataIndex: "lastIssueTime",
            key: "lastIssueTime",
            align: "center",
            width: "15%",
            render: (_, { lastIssueTime }) => moment(lastIssueTime, Common.FORMAT_DATE_TIME).format(Common.FORMAT_DATE_TIME),
        },
    ];

    useEffect(() => {
        setWaitingCount(issues?.filter((issue) => issue.actionStatus === "WAITING")?.length);
        setInActionCount(issues?.filter((issue) => issue.actionStatus === "IN_ACTION")?.length);
        setTimeout(() => {
            document.querySelector(".ant-layout-content").scrollTo({ top: reducerIssues.scrollPosition });
        }, 100);
    }, [issues]);

    const onTableChange: TableProps<TypeDTO.IssueDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<CarIssueDto>);
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    useEffect(() => {
        if (Object.keys(reducerIssues).length > 0) {
            setCurrentPage(reducerIssues?.currentPage);
            console.log("reducerIssues", reducerIssues);

            setSearch(
                searchData?.map((item) => {
                    const { id, defaultValue } = item;

                    return {
                        ...item,
                        defaultValue: reducerIssues[id] ?? defaultValue,
                    };
                })
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerIssues]);

    const dispatchIssues = (selectedId: number) => {
        dispatch(
            actionSetIssues({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const onSearchData = (value: SearchType) => {
        setSearchValue(value);

        setSelectedManagerName(value?.customerManagerName);
        requestApiIssueByCar(value);
    };

    const { loadingApiIssueByCar, requestApiIssueByCar, resultApiIssueByCar } = RequestIssue.useRequestApiIssueByCar();

    useEffect(() => {
        if (!resultApiIssueByCar) return;

        setIssues(resultApiIssueByCar.carIssues);
    }, [resultApiIssueByCar]);

    const summary = [
        {
            content: `${waitingCount}대 /`,
            icon: IconRedExclamtion,
        },
        {
            content: `${inActionCount}대`,
            icon: IconInAction,
        },
    ];

    return (
        <div className="pageWrapper" style={{ width: "100%" }}>
            <Search
                title={String.menu_maintenance_history_by_car}
                dataLength={issues?.length}
                summary={waitingCount || inActionCount ? summary : undefined}
                values={search}
                selectedName={selectedManagerName}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_USER])
                        ? () => navigate(Common.PAGE_MANAGE_MAINTENANCE_REGISTER + "/byCar")
                        : undefined
                }
            />
            <DataTable
                rowKey={(row: CarIssueDto) => row.car?.carId}
                disabledTitle
                loading={loadingApiIssueByCar}
                columns={columns}
                dataSource={issues}
                currentPage={currentPage}
                rowClassName={(value: CarIssueDto) => {
                    return value?.car?.carId === reducerIssues?.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerIssues?.pageSize}
                onRow={(value: CarIssueDto) => {
                    dispatchIssues(value.car.carId);
                    navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/" + value.car?.carNumber);
                }}
                onChange={onTableChange}
            />
        </div>
    );
};

export default PageMaintenanceByCar;
