import React from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { useAuthState } from "../provider/AuthProvider";

import * as Common from "../commons/common";
import * as Utils from "./utils";

// Pages
import LayoutDefault from "../layout/browser/LayoutDefault";
import PageError403 from "../pages/common/PageError403";
import PageError404 from "../pages/common/PageError404";
import PageError5XX from "../pages/common/PageError5XX";
import Dashboard from "../pages/browser/Dashboard";
import Login from "../pages/common/Login";
import PageRunnings from "../pages/browser/PageRunnings";
import PageCars from "../pages/browser/PageCars";
import PageMaintenance from "../pages/browser/PageMaintenance";
import PageMaintenanceByCar from "../pages/browser/PageMaintenanceByCar";
import PageSoc from "../pages/browser/PageSoc";
import PageAlarm from "../pages/browser/PageAlarm";
import PageMaintenanceGuide from "../pages/browser/PageMaintenanceGuide";
import PageStats from "../pages/browser/PageStats";
import PageTerminal from "../pages/browser/PageTerminal";
import PageDoormodule from "../pages/browser/PageDoormodule";
import PageModem from "../pages/browser/PageModem";
import PageCategory from "../pages/browser/PageCategory";
import PageMaker from "../pages/browser/PageMaker";
import PageCustomer from "../pages/browser/PageCustomer";
import PageUser from "../pages/browser/PageUser";
import PageGroup from "../pages/browser/PageGroup";
import PageService from "../pages/browser/PageService";
import PageVersion from "../pages/browser/PageVersion";
import PageRunningsDetails from "../pages/browser/PageRunningsDetails";
import PageCarDetail from "../pages/browser/PageCarDetail";
import PageSocDetail from "../pages/browser/PageSocDetail";
import PageMaintenanceDetail from "../pages/browser/PageMaintenanceDetail";
import PageGroupDetail from "../pages/browser/PageGroupDetail";
import KeepAlive from "react-activation";
import AlertPopup from "../components/common/AlertPopup";

const BrowserRouters = () => {
    const PrivateRoute = (props: any) => {
        const userDetails = useAuthState();

        if (userDetails?.user === null) {
            return <Navigate to={Common.PAGE_LOGIN} replace={true} />;
        }

        if (props.role !== undefined && props.role.length > 0) {
            const checkRole = userDetails?.user && props.role.includes(userDetails?.user.authority);
            return checkRole ? props.children : <PageError403 />;
        }

        return userDetails?.user ? props.children : <Navigate to={Common.PAGE_LOGIN} replace={true} />;
    };

    return (
        <BrowserRouter>
            <AlertPopup />
            <Routes>
                <Route path="*" element={<PageError404 />} />
                <Route path="/" element={<Navigate to={Common.PAGE_LOGIN} />} />
                <Route path={Common.PAGE_LOGIN} element={<Login />} />

                <Route path={Common.PAGE_ERROR_404} element={<PageError404 />} />
                <Route path={Common.PAGE_ERROR_403} element={<PageError403 />} />
                <Route path={Common.PAGE_ERROR_5XX} element={<PageError5XX />} />

                <Route
                    path={Common.PAGE_DASHBOARD}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <Dashboard />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_RUNNING}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageRunnings />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_RUNNING_DETAILS + "/:id1/:id2"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageRunningsDetails />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_RUNNING_DETAILS + "/:id1/:id2/:id3"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageRunningsDetails />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageCars />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_DETAIL + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_REGISTER}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN]}>
                            <LayoutDefault>
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_UPDATE + "/:id"}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN]}>
                            <LayoutDefault>
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceByCar />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCES_HISTORY}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenance key="history" />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenance key="history_id" />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/:id1/:id2"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_REGISTER}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_REGISTER + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_UPDATE + "/:id1/:id2"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ISSUE_SOCS}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageSoc />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ISSUE_SOCS + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageSocDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ALARM}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageAlarm key="alarm" />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ALARM + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageAlarm key="alarm_id" />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MAINTENANCE_GUIDES}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault>
                                <PageMaintenanceGuide />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_STATS}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER, Utils.getAuthority.ROLE_USER]}>
                            <LayoutDefault>
                                <PageStats />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_TERMINAL}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageTerminal />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_DOORMODULE}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageDoormodule />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MODEM}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageModem />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CATEGORY}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageCategory />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAKERS}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageMaker />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CUSTOMER}
                    element={
                        <PrivateRoute
                            role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER, Utils.getAuthority.ROLE_CUSTOMER_MANAGER]}
                        >
                            <LayoutDefault>
                                <PageCustomer />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_USER}
                    element={
                        <PrivateRoute
                            role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER, Utils.getAuthority.ROLE_CUSTOMER_MANAGER]}
                        >
                            <LayoutDefault>
                                <PageUser />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_GROUP}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageGroup />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_GROUP_DETAIL + "/:id"}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageGroupDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_GROUP_REGISTER}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageGroupDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_GROUP_UPDATE + "/:id"}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageGroupDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_SERVICE}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN]}>
                            <LayoutDefault>
                                <PageService />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_VERSION}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]}>
                            <LayoutDefault>
                                <PageVersion />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default BrowserRouters;
