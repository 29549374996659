import { useState, useEffect } from "react";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import type { TableProps } from "antd/es/table/InternalTable";
import { Dropdown, MenuProps } from "antd";

import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestDoorModule from "../../utils/requestApiDoorModule";

import Space from "antd/es/space/index";
import RedoOutlined from "@ant-design/icons/lib/icons/RedoOutlined";
import DataTable from "./DataTable";
import styles from "../mobile/CustomForm.module.css";

type historyType = {
    doorStatusHistoryId: number;
    historyTime: string;
    doorStatus: string;
};

function DoorModuleHistory({ isMobile, doorModuleId }: { isMobile?: boolean; doorModuleId?: number }) {
    const [sortedInfo, setSortedInfo] = useState<SorterResult<historyType>>({});
    const [histories, setHistories] = useState<Array<historyType> | undefined>();
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [lastPage, setLastPage] = useState(false);

    const columns: ColumnsType<historyType> = [
        {
            title: String.historyTime,
            dataIndex: "historyTime",
            key: "historyTime",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.historyTime, b.historyTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "historyTime" ? sortedInfo.order : null,
            ellipsis: true,
            render: (_, { historyTime }) =>
                isMobile
                    ? Utils.utcToLocalTime(historyTime, Common.FORMAT_MOBILE_DATE_TIME)
                    : Utils.utcToLocalTime(historyTime, Common.FORMAT_DATE_TIME),
        },
        {
            title: String.doorStatus,
            dataIndex: "doorStatus",
            key: "doorStatus",
            sorter: isMobile ? undefined : (a, b, sortOrder) => Utils.sortString(a.doorStatus, b.doorStatus, sortOrder),
            sortOrder: isMobile ? undefined : sortedInfo.columnKey === "doorStatus" ? sortedInfo.order : null,
            ellipsis: true,
        },
    ];

    const onTableChange: TableProps<historyType>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<historyType>);
    };

    useEffect(() => {
        if (!doorModuleId) return;

        requestApiDoorStatusHistoryList(doorModuleId, page);
    }, [doorModuleId, page]);

    const { loadingApiDoorStatusHistoryList, requestApiDoorStatusHistoryList, resultApiDoorStatusHistoryList } =
        RequestDoorModule.useRequestApiDoorStatusHistoryList();

    useEffect(() => {
        if (!resultApiDoorStatusHistoryList) return;

        const histories = resultApiDoorStatusHistoryList.doorStatusHistories.content;

        if (page === 0) {
            setHistories(histories);
        } else {
            setHistories((prevHistories) => {
                return [...(prevHistories || []), ...histories];
            });
        }

        setTotalElements(resultApiDoorStatusHistoryList.doorStatusHistories.totalElements);
        setLastPage(resultApiDoorStatusHistoryList.doorStatusHistories.last);
    }, [resultApiDoorStatusHistoryList]);

    const onScrollEnd = () => {
        if (!lastPage) {
            setPage(page + 1);
        }
    };

    const items: MenuProps["items"] = [
        {
            key: "more",
            label: (
                <span className="fc-font2 fw-bd fs-md btn-text" style={{ cursor: "pointer" }} onClick={() => setPage(page + 1)}>
                    <PlusOutlined /> 더보기
                </span>
            ),
        },
        {
            key: "refresh",
            label: (
                <span
                    className="fc-font2 fw-bd fs-md btn-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (page === 0) {
                            requestApiDoorStatusHistoryList(doorModuleId, page);
                        } else setPage(0);
                    }}
                >
                    <RedoOutlined /> {String.refresh}
                </span>
            ),
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8, maxWidth: isMobile ? "100%" : "calc(50% - 12px)" }}>
            <Space align="center" style={{ justifyContent: "space-between" }}>
                <Space>
                    {isMobile ? (
                        <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                            {String.doorModuleStatusHistory}
                        </p>
                    ) : (
                        <h4 style={{ margin: 0 }}>{String.doorModuleStatusHistory}</h4>
                    )}
                    <span className="fc-font2 fs-sm">
                        {histories?.length ? Utils.addComma(histories?.length) : 0}건 / {Utils.addComma(totalElements)}건
                    </span>
                </Space>
                {histories?.length > 0 &&
                    (isMobile ? (
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <span className={`${styles.editBtn} fc-font3 fw-bd fs-sm btn-text`}>
                                <MenuOutlined /> 목록
                            </span>
                        </Dropdown>
                    ) : (
                        <Space>
                            <span className="fc-font2 fw-bd fs-md btn-text" style={{ cursor: "pointer" }} onClick={() => setPage(page + 1)}>
                                <PlusOutlined /> 더보기
                            </span>
                            <span className="fc-font3 fs-sm">|</span>
                            <span
                                className="fc-font2 fw-bd fs-md btn-text"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (page === 0) {
                                        requestApiDoorStatusHistoryList(doorModuleId, page);
                                    } else setPage(0);
                                }}
                            >
                                <RedoOutlined /> {String.refresh}
                            </span>
                        </Space>
                    ))}
            </Space>
            <DataTable
                isMobile={isMobile}
                rowKey="doorStatusHistoryId"
                disabledTitle
                disabledPagination={!isMobile}
                loading={loadingApiDoorStatusHistoryList}
                columns={columns}
                dataSource={histories}
                onChange={onTableChange}
                scroll={isMobile ? undefined : 500}
                tableSize={isMobile ? 10 : 20}
                onScrollEnd={onScrollEnd}
            />
        </div>
    );
}

export default DoorModuleHistory;
