import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosFormWithAuth, utilAxiosWithAuth } from "./customAxios";
import { SearchType } from "../components/browser/Maintenances";

const DEBUG = true;
const PAGE_SIZE = 100;

export const useRequestApiIssueRegister = () => {
    const requestAxiosApiIssueRegister = async (data: TypeDTO.IssueDto) => {
        const response = await utilAxiosFormWithAuth().post(Request.ISSUE_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiIssueRegister,
        error: errorApiIssueRegister,
        data: resultApiIssueRegister,
        execute: requestApiIssueRegister,
    } = useAsyncAxios(requestAxiosApiIssueRegister);

    useEffect(() => {
        if (resultApiIssueRegister === null) return;
        DEBUG && console.log("resultApiIssueRegister", resultApiIssueRegister);
    }, [resultApiIssueRegister]);

    useEffect(() => {
        if (errorApiIssueRegister === null) return;
        DEBUG && console.log("errorApiIssueRegister", errorApiIssueRegister);
    }, [errorApiIssueRegister]);

    return {
        loadingApiIssueRegister,
        errorApiIssueRegister,
        resultApiIssueRegister,
        requestApiIssueRegister,
    };
};

export const useRequestApiIssueList = () => {
    const requestAxiosApiIssueList = async (searchType: SearchType, page: number, size: number) => {
        const response = await utilAxiosWithAuth().get(Request.ISSUES_URL, {
            params: { ...searchType, page: page, size: PAGE_SIZE },
        });

        return response.data;
    };

    const {
        loading: loadingApiIssueList,
        error: errorApiIssueList,
        data: resultApiIssueList,
        execute: requestApiIssueList,
    } = useAsyncAxios(requestAxiosApiIssueList);

    useEffect(() => {
        if (resultApiIssueList === null) return;
        DEBUG && console.log("resultApiIssueList", resultApiIssueList);
    }, [resultApiIssueList]);

    useEffect(() => {
        if (errorApiIssueList === null) return;
        DEBUG && console.log("errorApiIssueList", errorApiIssueList);
    }, [errorApiIssueList]);

    return {
        loadingApiIssueList,
        errorApiIssueList,
        resultApiIssueList,
        requestApiIssueList,
    };
};

export const useRequestApiIssueInfo = () => {
    const requestAxiosApiIssueInfo = async (issueId: number) => {
        const response = await utilAxiosWithAuth().get(Request.ISSUE_URL + issueId);

        return response.data;
    };

    const {
        loading: loadingApiIssueInfo,
        error: errorApiIssueInfo,
        data: resultApiIssueInfo,
        execute: requestApiIssueInfo,
    } = useAsyncAxios(requestAxiosApiIssueInfo);

    useEffect(() => {
        if (resultApiIssueInfo === null) return;
        DEBUG && console.log("resultApiIssueInfo", resultApiIssueInfo);
    }, [resultApiIssueInfo]);

    useEffect(() => {
        if (errorApiIssueInfo === null) return;
        DEBUG && console.log("errorApiIssueInfo", errorApiIssueInfo);
    }, [errorApiIssueInfo]);

    return {
        loadingApiIssueInfo,
        errorApiIssueInfo,
        resultApiIssueInfo,
        requestApiIssueInfo,
    };
};

export const useRequestApiIssueUpdate = () => {
    const requestAxiosApiIssueUpdate = async (issueId: number, data: TypeDTO.IssueDto) => {
        const response = await utilAxiosFormWithAuth().put(Request.ISSUE_URL + issueId, data);

        return response.data;
    };

    const {
        loading: loadingApiIssueUpdate,
        error: errorApiIssueUpdate,
        data: resultApiIssueUpdate,
        execute: requestApiIssueUpdate,
    } = useAsyncAxios(requestAxiosApiIssueUpdate);

    useEffect(() => {
        if (resultApiIssueUpdate === null) return;
        DEBUG && console.log("resultApiIssueUpdate", resultApiIssueUpdate);
    }, [resultApiIssueUpdate]);

    useEffect(() => {
        if (errorApiIssueUpdate === null) return;
        DEBUG && console.log("errorApiIssueUpdate", errorApiIssueUpdate);
    }, [errorApiIssueUpdate]);

    return {
        loadingApiIssueUpdate,
        errorApiIssueUpdate,
        resultApiIssueUpdate,
        requestApiIssueUpdate,
    };
};

export const useRequestApiIssueDelete = () => {
    const requestAxiosApiIssueDelete = async (issueId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.ISSUE_URL + issueId);

        return response.data;
    };

    const {
        loading: loadingApiIssueDelete,
        error: errorApiIssueDelete,
        data: resultApiIssueDelete,
        execute: requestApiIssueDelete,
    } = useAsyncAxios(requestAxiosApiIssueDelete);

    useEffect(() => {
        if (resultApiIssueDelete === null) return;
        DEBUG && console.log("resultApiIssueDelete", resultApiIssueDelete);
    }, [resultApiIssueDelete]);

    useEffect(() => {
        if (errorApiIssueDelete === null) return;
        DEBUG && console.log("errorApiIssueDelete", errorApiIssueDelete);
    }, [errorApiIssueDelete]);

    return {
        loadingApiIssueDelete,
        errorApiIssueDelete,
        resultApiIssueDelete,
        requestApiIssueDelete,
    };
};

export const useRequestApiIssueFile = () => {
    const requestAxiosApiIssueFile = async (issueFileId: number) => {
        const response = await utilAxiosFormWithAuth().get(Request.ISSUE_FILE_DOWNLOAD_URL + issueFileId);
        return response.data;
    };

    const {
        loading: loadingApiIssueFile,
        error: errorApiIssueFile,
        data: resultApiIssueFile,
        execute: requestApiIssueFile,
    } = useAsyncAxios(requestAxiosApiIssueFile);

    useEffect(() => {
        if (resultApiIssueFile === null) return;
        DEBUG && console.log("resultApiIssueFile", resultApiIssueFile);
    }, [resultApiIssueFile]);

    useEffect(() => {
        if (errorApiIssueFile === null) return;
        DEBUG && console.log("errorApiIssueFile", errorApiIssueFile);
    }, [errorApiIssueFile]);

    return {
        loadingApiIssueFile,
        errorApiIssueFile,
        resultApiIssueFile,
        requestApiIssueFile,
    };
};

export const useRequestApiIssueByCar = () => {
    const requestAxiosApiIssueByCar = async (data: SearchType) => {
        const response = await utilAxiosWithAuth().get(Request.ISSUE_BY_CAR_URL, { params: data });

        return response.data;
    };

    const {
        loading: loadingApiIssueByCar,
        error: errorApiIssueByCar,
        data: resultApiIssueByCar,
        execute: requestApiIssueByCar,
    } = useAsyncAxios(requestAxiosApiIssueByCar);

    useEffect(() => {
        if (resultApiIssueByCar === null) return;
        DEBUG && console.log("resultApiIssueByCar", resultApiIssueByCar);
    }, [resultApiIssueByCar]);

    useEffect(() => {
        if (errorApiIssueByCar === null) return;
        DEBUG && console.log("errorApiIssueByCar", errorApiIssueByCar);
    }, [errorApiIssueByCar]);

    return {
        loadingApiIssueByCar,
        errorApiIssueByCar,
        resultApiIssueByCar,
        requestApiIssueByCar,
    };
};
