import { ReactElement, useEffect, useState } from "react";
import type { TableProps } from "antd/es/table/InternalTable";
import Row from "antd/es/grid/row";
import Space from "antd/es/space/index";
import Table from "antd/es/table/Table";

import styles from "./DataTable.module.css";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

type summaryType = {
    content: string;
    icon: string;
};

function DataTable({
    title,
    button,
    scroll,
    summary,
    isMobile,
    currentPage,
    disabledTitle,
    disabledPagination,
    loading,
    columns,
    dataSource,
    components,
    rowKey,
    onRowKey,
    onSelected,
    onRow,
    selectedRowKeys,
    onChange,
    tableSize,
    rowClassName,
    onScrollEnd,
    isPageable,
    enableMoreData,
    onMoreData,
}: {
    title?: string;
    button?: ReactElement;
    scroll?: number | string;
    summary?: Array<summaryType>;
    isMobile?: boolean;
    currentPage?: number;
    disabledTitle?: boolean;
    disabledPagination?: boolean;
    loading?: boolean;
    columns: any;
    dataSource: any;
    components?: any;
    rowKey: any;
    onRowKey?: (record: any) => string;
    onSelected?: (values: any, selectedRowKeys: React.Key[]) => void;
    onRow?: (values: any) => void;
    selectedRowKeys?: Array<string>;
    onChange?: TableProps<any>["onChange"];
    tableSize?: number;
    rowClassName?: (record: any, index: number) => string;
    onScrollEnd?: () => void;
    isPageable?: boolean;
    enableMoreData?: boolean;
    onMoreData?: () => void;
}) {
    const [check, setCheck] = useState<Array<string> | React.Key[]>();
    const [selectedRow, setSelectedRow] = useState<number>();

    useEffect(() => {
        setSelectedRow(undefined);
    }, [dataSource]);

    useEffect(() => {
        setCheck(selectedRowKeys);
    }, [selectedRowKeys]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
        const target = e.currentTarget;
        const { scrollTop, scrollHeight, clientHeight } = target;

        if (scrollTop + clientHeight >= scrollHeight) {
            onScrollEnd && onScrollEnd();
        }
    };

    return (
        <div className={styles.tableWrapper}>
            {!disabledTitle && (
                <Row className="titleWrapper" justify="space-between">
                    {title && (
                        <Space align="center">
                            {isMobile ? (
                                <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                                    {title} 목록
                                </p>
                            ) : (
                                <h4 style={{ margin: 0 }}>{title} 목록</h4>
                            )}
                            {(dataSource || summary) && (
                                <Space size={4}>
                                    {dataSource ? <span className="fw-rg fs-sm fc-font2">총 {dataSource ? dataSource.length : 0}건</span> : ""}
                                    {summary && (
                                        <Row className="fw-rg fs-sm fc-font2" align="middle">
                                            <span className="fw-rg fs-sm fc-font2">{"("}</span>
                                            <Row className="fw-rg fs-sm fc-font2" align="middle" style={{ gap: 2 }}>
                                                {summary.map((item) => {
                                                    return (
                                                        <>
                                                            <img alt="" width={16} height={16} src={item.icon} />
                                                            <span className="fw-rg fs-sm fc-font2">{item.content}</span>
                                                        </>
                                                    );
                                                })}
                                            </Row>
                                            <span className="fw-rg fs-sm fc-font2">{")"}</span>
                                        </Row>
                                    )}
                                </Space>
                            )}
                        </Space>
                    )}
                    <Space size={16}>{button !== undefined && button}</Space>
                </Row>
            )}
            <Table
                className={onRow !== undefined ? `${styles.table} ${styles.pointerTable}` : `${styles.table}`}
                rowClassName={rowClassName || ((record, index) => (record === selectedRow ? styles.selectedRow : ""))}
                loading={loading}
                pagination={
                    !disabledPagination && {
                        pageSize: tableSize,
                        defaultPageSize: 20,
                        position: ["bottomCenter"],
                        simple: isMobile,
                        showSizeChanger: false,
                        showTotal: (total, range) => {
                            return (
                                <Space>
                                    <span>{` ${range[0]}-${range[1]}건 / 총 ${total}건 `}</span>
                                    {isPageable && (
                                        <Button icon={<PlusOutlined />} disabled={!enableMoreData} onClick={() => onMoreData && onMoreData()} />
                                    )}
                                </Space>
                            );
                        },
                        current: currentPage,
                    }
                }
                rowSelection={
                    onSelected === undefined
                        ? undefined
                        : {
                              type: "checkbox",
                              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                                  onSelected !== undefined && onSelected(selectedRows, selectedRowKeys);
                                  setCheck(selectedRowKeys);
                              },
                              selectedRowKeys: check,
                          }
                }
                rowKey={rowKey ? rowKey : onRowKey ? (record) => onRowKey(record) : undefined}
                components={components}
                columns={columns}
                dataSource={dataSource}
                onRow={(record, index) => {
                    return {
                        onClick: () => {
                            setSelectedRow(record);
                            onRow !== undefined && onRow(record);
                        },
                    };
                }}
                onChange={onChange}
                scroll={{ y: scroll || "none" }}
                onScroll={handleScroll}
            />
        </div>
    );
}

export default DataTable;
