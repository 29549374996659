import moment from "moment";
import * as Common from "../commons/common";
import * as Strings from "../commons/string";

import { useEffect, useRef } from "react";
import { SortOrder } from "antd/es/table/interface";
import { useNavigate } from "react-router";
import { useAliveController } from "react-activation";
import { AlertType } from "../provider/AlertProvider";

import CryptoJS from "crypto-js";

declare const window: typeof globalThis & {
    Android: any;
    webkit: any;
};

export const divideStartDateTimeNEndTime = (startTime: string, endTime: string) => {
    const start = moment(startTime, Common.FORMAT_DATE_TIME);
    const end = moment(endTime, Common.FORMAT_DATE_TIME);
    let sameDay = false;

    let retEndTime = end.format(Common.FORMAT_DATE_TIME);
    if (start.isSame(end, "day")) {
        retEndTime = end.format(Common.FORMAT_TIME);
        sameDay = true;
    }

    return {
        startDate: start.format(Common.FORMAT_DATE),
        startTime: start.format(Common.FORMAT_TIME),
        endTime: retEndTime,
        sameDay: sameDay,
    };
};

export const secToTime = (sec: number) => {
    const secs = sec % 60;
    sec = (sec - secs) / 60;
    const mins = sec % 60;
    const hrs = (sec - mins) / 60;

    return (hrs < 100 ? to2Digit(hrs) : hrs) + ":" + to2Digit(mins) + ":" + to2Digit(secs);
};

export const msToTime = (msec: number) => {
    const ms = msec % 1000;
    msec = (msec - ms) / 1000;
    const secs = msec % 60;
    msec = (msec - secs) / 60;
    const mins = msec % 60;
    const hrs = (msec - mins) / 60;

    return (hrs < 100 ? to2Digit(hrs) : hrs) + ":" + to2Digit(mins) + ":" + to2Digit(secs);
};

export const to2Digit = (number: number) => {
    return ("0" + number.toFixed(0)).slice(-2);
};

export const sortDate = (a: string, b: string, sortOrder?: SortOrder): number => {
    if (a != null && b != null) {
        return moment(a).unix() - moment(b).unix();
    }
    if (a) {
        return sortOrder === "ascend" ? -1 : 1;
    }
    if (b) {
        return sortOrder === "ascend" ? 1 : -1;
    }
    return 0;
};

export const sortNumber = (a: number, b: number, sortOrder?: SortOrder): number => {
    if (a != null && b != null) {
        return a - b;
    }
    if (a) {
        return sortOrder === "ascend" ? -1 : 1;
    }
    if (b) {
        return sortOrder === "ascend" ? 1 : -1;
    }
    return 0;
};

export const sortString = (a: string, b: string, sortOrder?: SortOrder): number => {
    if (a != null && b != null) {
        return a.localeCompare(b);
    }
    if (a) {
        return sortOrder === "ascend" ? -1 : 1;
    }
    if (b) {
        return sortOrder === "ascend" ? 1 : -1;
    }
    return 0;
};

export const sortBoolean = (a: boolean, b: boolean, sortOrder?: SortOrder): number => {
    if (a != null && b != null) {
        return a.toString().localeCompare(b.toString());
    }
    if (a) {
        return sortOrder === "ascend" ? -1 : 1;
    }
    if (b) {
        return sortOrder === "ascend" ? 1 : -1;
    }
    return 0;
};

export const useNavigateWithRefresh = () => {
    const navigate = useNavigate();
    const { refresh } = useAliveController();

    const navigateWithRefresh = (path: string, state?: any) => {
        refresh(path);
        navigate(path, { state: state });
    };

    return { navigateWithRefresh };
};

export enum getAuthority {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_MANAGER = "ROLE_MANAGER",
    ROLE_CUSTOMER_MANAGER = "ROLE_CUSTOMER_MANAGER",
    ROLE_CUSTOMER = "ROLE_CUSTOMER",
    ROLE_USER = "ROLE_USER",
    ROLE_APPLICATION = "ROLE_APPLICATION",
    ROLE_CAR = "ROLE_CAR",
}

export enum convertAuthority {
    ROLE_ADMIN = "최고관리자",
    ROLE_MANAGER = "관리자",
    ROLE_CUSTOMER_MANAGER = "고객관리자",
    ROLE_CUSTOMER = "고객",
    ROLE_USER = "사용자",
}

export enum carrier {
    SKT = "SKT",
    KT = "KT",
    LGU_PLUS = "LG U+",
}

export enum actionStatus {
    WAITING = "대기",
    IN_ACTION = "조치중",
    COMPLETED = "완료",
}

export enum issueType {
    SW = "SW",
    HW = "HW",
    HW_MAKER = "제조사",
    REGULAR = "정기 점검",
}

export enum alarmType {
    WARNING = "경고",
    ERROR = "에러",
}

export const checkUnAuthorizedToken = (error: any | null) => {
    if (error === null) return false;

    if (error.response.data.code === AlertType.UNAUTHORIZED && error.response.data.message === "Unauthorized token") {
        return true;
    }

    return false;
};

const secretPass = "ezMobility-2024";
export const getLocalStorage = (key: string) => {
    const item = localStorage.getItem(key);
    if (!item) return item;

    const decrypted = CryptoJS.AES.decrypt(item, secretPass);

    try {
        const converted = decrypted.toString(CryptoJS.enc.Utf8);
        return converted;
    } catch (error) {
        removeLocalStorage(key);
        return undefined;
    }
};

export const setLocalStorage = (key: string, value: string) => {
    const encryped = CryptoJS.AES.encrypt(value, secretPass).toString();
    return localStorage.setItem(key, encryped);
};

export const removeLocalStorage = (key: string) => {
    return localStorage.removeItem(key);
};

export const useInterval = (callback: () => void, interval: number | null) => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current !== undefined) savedCallback.current();
        }
        if (interval !== null) {
            const id: NodeJS.Timer = setInterval(tick, interval);
            return () => clearInterval(id);
        }
    }, [interval]);
};

export const utcToLocalTime = (utcTime: string | undefined, format: string) => {
    if (utcTime === undefined) return "";

    const localTime = moment.utc(utcTime).toDate();
    return moment(localTime).format(format);
};

export const roleCheck = (authority: string | undefined, permissionRole: string[]): boolean => {
    if (authority && permissionRole.includes(authority)) {
        return true;
    }
    return false;
};

export const checkNumberData = (data: number) => {
    return data === null ? "-" : !isNaN(data) && isFinite(data) ? data?.toFixed(1).replace(/\.?0+$/, "") : data;
};

export const searchFilter = (item: any, value: string | number, property: string) => {
    if (!value) return true;

    const propertyValue = item?.[property];
    if (!propertyValue) return false;

    if (typeof value === "string" && typeof propertyValue === "string") {
        return propertyValue.includes(value);
    }

    if (typeof value === "number" && typeof propertyValue === "number") {
        return propertyValue === value;
    }

    return false;
};

export const removeDuplicateObjects = (arrays?: any[]) => {
    return arrays
        ?.filter((option) => option.value)
        ?.reduce((uniqueValues, option) => {
            if (!uniqueValues.some((v: any) => v.value === option.value)) {
                uniqueValues.push(option);
            }
            return uniqueValues;
        }, [])
        ?.sort((a: any, b: any) => a.value - b.value);
};

export const addParticle = (word: string, additional: string) => {
    return word + ((word.charCodeAt(word.length - 1) - 0xac00) % 28 > 0 ? "을" : "를") + " " + additional;
};

export const getTypeText = (type: "register" | "edit" | "delete") => {
    return {
        register: Strings.register,
        edit: Strings.update,
        delete: Strings.remove,
    }[type];
};

export const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const date = now.getDate();
    const hour = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const today = year + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date);
    const time = (hour < 10 ? "0" + hour : hour) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);

    return { date: today, time: time };
};
export const convertPhone = (phone?: string) => {
    if (!phone) return "";

    if (phone.length === 8) {
        return phone.replace(/^(\d{4})(\d{4})$/, "$1-$2");
    } else if (phone.length === 9) {
        return phone.replace(/^(\d{2})(\d{3})(\d{4})$/, "$1-$2-$3");
    } else {
        return phone.replace(/^(\d{3})(\d{3,4})(\d{4})$/, "$1-$2-$3");
    }
};

export const isBukgiOrYaxing = (makerName?: string) => {
    return makerName === Common.VehicleMaker.Bukgi || makerName === Common.VehicleMaker.Yaxing;
};

export const convertInputPhone = (event: React.FormEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{3})(\d{4})(\d+)$/, "$1-$2-$3")
        .replace(/^(\d{3})(\d{4})$/, "$1-$2-")
        .replace(/^(\d{3})(\d+)$/, "$1-$2")
        .replace(/^(\d{3})$/, "$1-");
};

export const sendFCMToken = (value?: any) => {
    const token = value ? value.token : JSON.parse(getLocalStorage(Common.CONTEXT_AUTH) || "{}").token;
    const user = value ? value.user : JSON.parse(getLocalStorage(Common.CONTEXT_AUTH) || "{}").user;

    if (token && user) {
        if (window.Android) window.Android.sendFCMToken(user.username, token);
        else if (window.webkit) {
            const message = {
                username: user.username,
                loginToken: token,
            };
            window.webkit.messageHandlers.sendFCMToken.postMessage(message);
        } else console.log("Not Android");
    }
};

export const onEvent = (eventType: string, listener: (event?: any) => void) => {
    document.addEventListener(eventType, listener);
};

export const offEvent = (eventType: string, listener: (event?: any) => void) => {
    document.removeEventListener(eventType, listener);
};

export const triggerEvent = (eventType: string, data?: any) => {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
};

export const isPossibleAutoLogin = () => {
    const savedID = getLocalStorage(Common.CONTEXT_SAVED_ID);
    const savedPW = getLocalStorage(Common.CONTEXT_SAVED_PW);
    if (savedID && savedID.length > 0 && savedPW && savedPW.length) {
        return { isPossible: true, username: savedID, password: savedPW };
    }

    return { isPossible: false };
};

export const initModalStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_MODAL, String(0));
};

export const addModalStack = () => {
    let currStack = getModalStack();
    currStack++;
    sessionStorage.setItem(Common.SESSION_SHOW_MODAL, String(currStack));
};

export const popModalStack = () => {
    let currStack = getModalStack();
    currStack--;
    if (currStack < 0) currStack = 0;
    sessionStorage.setItem(Common.SESSION_SHOW_MODAL, String(currStack));
};

export const initViewStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_VIEW, String(0));
};

export const addViewStack = () => {
    let currStack = getViewStack();
    currStack++;
    sessionStorage.setItem(Common.SESSION_SHOW_VIEW, String(currStack));
};

export const popViewStack = () => {
    let currStack = getViewStack();
    currStack--;
    if (currStack < 0) currStack = 0;
    sessionStorage.setItem(Common.SESSION_SHOW_VIEW, String(currStack));
};

export const getModalStack = () => {
    return Number(sessionStorage.getItem(Common.SESSION_SHOW_MODAL));
};

export const initDrawerStack = () => {
    sessionStorage.setItem(Common.SESSION_SHOW_DRAWER, String(0));
};

export const addDrawerStack = () => {
    let currStack = getDrawerStack();
    currStack++;
    sessionStorage.setItem(Common.SESSION_SHOW_DRAWER, String(currStack));
};

export const popDrawerStack = () => {
    let currStack = getDrawerStack();
    currStack--;
    if (currStack < 0) currStack = 0;
    sessionStorage.setItem(Common.SESSION_SHOW_DRAWER, String(currStack));
};

export const getDrawerStack = () => {
    return Number(sessionStorage.getItem(Common.SESSION_SHOW_DRAWER));
};

export const getViewStack = () => {
    return Number(sessionStorage.getItem(Common.SESSION_SHOW_VIEW));
};

export const addComma = (price: string | number) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
