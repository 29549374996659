import { useState, useEffect } from "react";
import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import type { TableProps } from "antd/es/table/InternalTable";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import { Dropdown, MenuProps } from "antd";

import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";
import * as RequestDoorModule from "../../utils/requestApiDoorModule";

import RedoOutlined from "@ant-design/icons/lib/icons/RedoOutlined";
import Space from "antd/es/space/index";
import IconRedExclamtion from "../../assets/images/marker/circle_battery_very_low.png";
import IconYellowExclamtion from "../../assets/images/marker/circle_battery_low.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";
import DataTable from "./DataTable";
import styles from "../mobile/CustomForm.module.css";

type historyType = {
    subBatteryHistoryId: number;
    vehicleCode: number;
    vehicleNumber: string;
    historyTime: string;
    voltage: number;
    subBatteryStatus: string;
};

function SubBatteryHistory({ isMobile, doorModuleId }: { isMobile?: boolean; doorModuleId?: number }) {
    const [sortedInfo, setSortedInfo] = useState<SorterResult<historyType>>({});
    const [histories, setHistories] = useState<Array<historyType> | undefined>();
    const [page, setPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState(0);
    const [lastPage, setLastPage] = useState(false);

    const columns: ColumnsType<historyType> = [
        {
            title: String.historyTime,
            dataIndex: "historyTime",
            key: "historyTime",
            width: "50%",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.historyTime, b.historyTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "historyTime" ? sortedInfo.order : null,
            ellipsis: true,
            render: (_, { historyTime }) =>
                isMobile
                    ? Utils.utcToLocalTime(historyTime, Common.FORMAT_MOBILE_DATE_TIME)
                    : Utils.utcToLocalTime(historyTime, Common.FORMAT_DATE_TIME),
        },
        {
            title: isMobile ? "전압" : String.voltage,
            dataIndex: "voltage",
            key: "voltage",
            width: "25%",
            sorter: isMobile ? undefined : (a, b, sortOrder) => Utils.sortNumber(a.voltage, b.voltage, sortOrder),
            sortOrder: isMobile ? undefined : sortedInfo.columnKey === "voltage" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: String.subBatteryStatus,
            dataIndex: "subBatteryStatus",
            key: "subBatteryStatus",
            width: "25%",
            sorter: isMobile ? undefined : (a, b, sortOrder) => Utils.sortString(a.subBatteryStatus, b.subBatteryStatus, sortOrder),
            sortOrder: isMobile ? undefined : sortedInfo.columnKey === "subBatteryStatus" ? sortedInfo.order : null,
            ellipsis: true,
            render: (_, { subBatteryStatus }) => {
                switch (subBatteryStatus) {
                    case "NORMAL":
                        return <img alt="icon" src={IconCheck} width={20} height={20} />;
                    case "LOW":
                        return <img alt="icon" src={IconYellowExclamtion} width={20} height={20} />;
                    case "VERY_LOW":
                        return <img alt="icon" src={IconRedExclamtion} width={20} height={20} />;
                    default:
                        return;
                }
            },
        },
    ];

    const onTableChange: TableProps<historyType>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<historyType>);
    };

    useEffect(() => {
        if (!doorModuleId) return;

        requestApiSubBatteryHistoryList(doorModuleId, page);
    }, [doorModuleId, page]);

    const { loadingApiSubBatteryHistoryList, requestApiSubBatteryHistoryList, resultApiSubBatteryHistoryList } =
        RequestDoorModule.useRequestApiSubBatteryHistoryList();

    useEffect(() => {
        if (!resultApiSubBatteryHistoryList) return;

        const histories = resultApiSubBatteryHistoryList.subBatteryHistories.content;

        if (page === 0) {
            setHistories(histories);
        } else {
            setHistories((prevHistories) => {
                return [...(prevHistories || []), ...histories];
            });
        }

        setTotalElements(resultApiSubBatteryHistoryList.subBatteryHistories.totalElements);
        setLastPage(resultApiSubBatteryHistoryList.subBatteryHistories.last);
    }, [resultApiSubBatteryHistoryList]);

    const onScrollEnd = () => {
        if (!lastPage) {
            setPage(page + 1);
        }
    };

    const items: MenuProps["items"] = [
        {
            key: "more",
            label: (
                <span className="fc-font2 fw-bd fs-md btn-text" style={{ cursor: "pointer" }} onClick={() => setPage(page + 1)}>
                    <PlusOutlined /> 더보기
                </span>
            ),
        },
        {
            key: "refresh",
            label: (
                <span
                    className="fc-font2 fw-bd fs-md btn-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (page === 0) {
                            requestApiSubBatteryHistoryList(doorModuleId, page);
                        } else setPage(0);
                    }}
                >
                    <RedoOutlined /> {String.refresh}
                </span>
            ),
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 8, maxWidth: isMobile ? "100%" : "calc(50% - 12px)" }}>
            <Space align="center" style={{ justifyContent: "space-between" }}>
                <Space>
                    {isMobile ? (
                        <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                            {String.subBatteryHistory}
                        </p>
                    ) : (
                        <h4 style={{ margin: 0 }}>{String.subBatteryHistory}</h4>
                    )}
                    <span className="fc-font2 fs-sm">
                        {histories?.length ? Utils.addComma(histories?.length) : 0}건 / {Utils.addComma(totalElements)}건
                    </span>
                </Space>
                {histories?.length > 0 &&
                    (isMobile ? (
                        <Dropdown menu={{ items }} placement="bottomRight">
                            <span className={`${styles.editBtn} fc-font3 fw-bd fs-sm btn-text`}>
                                <MenuOutlined /> 목록
                            </span>
                        </Dropdown>
                    ) : (
                        <Space>
                            <span className="fc-font2 fw-bd fs-md btn-text" style={{ cursor: "pointer" }} onClick={() => setPage(page + 1)}>
                                <PlusOutlined /> 더보기
                            </span>
                            <span className="fc-font3 fs-sm">|</span>
                            <span
                                className="fc-font2 fw-bd fs-md btn-text"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (page === 0) {
                                        requestApiSubBatteryHistoryList(doorModuleId, page);
                                    } else setPage(0);
                                }}
                            >
                                <RedoOutlined /> {String.refresh}
                            </span>
                        </Space>
                    ))}
            </Space>
            <DataTable
                isMobile={isMobile}
                rowKey="subBatteryHistoryId"
                disabledTitle
                disabledPagination={!isMobile}
                loading={loadingApiSubBatteryHistoryList}
                columns={columns}
                dataSource={histories}
                onChange={onTableChange}
                scroll={isMobile ? undefined : 500}
                tableSize={isMobile ? 10 : 20}
                onScrollEnd={onScrollEnd}
            />
        </div>
    );
}

export default SubBatteryHistory;
