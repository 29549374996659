import { useEffect, useState } from "react";
import Input from "antd/es/input/index";
import Form from "antd/es/form/index";
import Select from "antd/es/select/index";
import Divider from "antd/es/divider/index";
import CustomForm, { viewType } from "../mobile/CustomForm";
import DataTable from "../common/DataTable";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useLocation, useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as RequestCar from "../../utils/requestApiCar";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestCategory from "../../utils/requestApiCategory";
import * as RequestCustomer from "../../utils/requestAuthCustomer";
import * as RequestSwVer from "../../utils/requestApiSwVer";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

const TabCarInfo = ({ carId }: { carId?: number }) => {
    const userDetails = useAuthState();
    const alert = useAlert();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { Option } = Select;
    const [form] = Form.useForm();

    const [type, setType] = useState<viewType>("view");
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [categories, setCategories] = useState<Array<TypeDTO.CategoryDto>>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [swVers, setSwVers] = useState<Array<TypeDTO.SwVerDto>>();
    const [selectedMakerId, setSelectedMakerId] = useState<number>();
    const [selectedModelId, setSelectedModelId] = useState<number>();
    const [customerPhone, setCustomerPhone] = useState<string>();

    useEffect(() => {
        requestApiMakerList();
        requestAuthCustomerList();
        requestApiSwVerList();
        requestApiCategoryList();

        if (pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL)) {
            setType("view");
        } else if (pathname.includes(Common.PAGE_MANAGE_CAR_REGISTER)) {
            setType("register");
        } else {
            setType("edit");
        }
    }, []);

    useEffect(() => {
        carId && requestApiCarInfo(carId);
    }, [carId]);

    const onChangeIdNumber = (event: React.FormEvent<HTMLInputElement>) => {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value
            .toUpperCase()
            .replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "")
            .replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, "");
    };

    const columns: ColumnsType<TypeDTO.CarNumberHistoryDto> = [
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            align: "center",
            ellipsis: false,
        },
        {
            title: String.customerName,
            dataIndex: ["customer", "customerName"],
            key: "customerName",
            align: "center",
            ellipsis: false,
            render: (_, { customer }) => customer?.customerName || String.dash,
        },
        {
            title: String.changeTime,
            dataIndex: "historyTime",
            key: "historyTime",
            align: "center",
            ellipsis: true,
            render: (_, { historyTime }) => Utils.utcToLocalTime(historyTime, Common.FORMAT_SHORT_DATE),
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        { name: "carNumber", label: String.carNumber, required: true },
        {
            name: "idNumber",
            label: String.identityNumber,
            required: true,
            input: (
                <Input
                    placeholder="차대 번호"
                    maxLength={17}
                    onInput={onChangeIdNumber}
                    className={type === "view" ? "disabled-input" : ""}
                    disabled={type === "view"}
                />
            ),
        },
        {
            name: ["customer", "customerId"],
            label: String.customer,
            customSelector: {
                title: String.customer,
                typeDetail: "customer",
                placeholder: type === "view" ? "-" : "고객",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: car?.customer?.customerName,
            },
        },
        {
            name: ["customer", "customerPhone"],
            label: String.phone,
            input:
                type === "view" ? (
                    <a target="_parent" href={`tel:${customerPhone}`} className="link-text" /* iOS 기능 확인 후 클래스 className="link-text"로 변경 */>
                        {customerPhone ? customerPhone : "-"}
                    </a>
                ) : (
                    <Input disabled={true} placeholder="고객 선택 시 자동 입력" />
                ),
        },
        {
            name: ["makerModel", "maker", "makerId"],
            label: String.maker,
            required: true,
            customSelector: {
                title: String.maker,
                typeDetail: "maker",
                placeholder: type === "view" ? "-" : "제조사",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                required: true,
                defaultValue: car?.makerModel?.maker?.makerName,
                onChange: (value: any) => setSelectedMakerId(value),
            },
        },
        {
            name: ["makerModel", "makerModelId"],
            label: String.carModel,
            required: true,
            customSelector: {
                title: String.carModel,
                typeDetail: "model",
                options: makers
                    ?.find((maker) => maker?.makerId === selectedMakerId)
                    ?.makerModels?.map((model) => {
                        return { value: model?.makerModelId, label: model?.makerModelName };
                    }),
                placeholder: type === "view" ? "-" : !selectedMakerId ? "제조사 입력 후 선택 가능" : "차량 모델",
                className: type === "view" ? "disabled-selector" : !selectedMakerId ? "locked-selector" : "",
                disabled: type === "view",
                required: true,
                defaultValue: car?.makerModel?.makerModelName,
                onChange: (value: any) => setSelectedModelId(value),
            },
        },
        {
            name: ["category", "categoryId"],
            label: String.type,
            required: true,
            customSelector: {
                title: String.type,
                typeDetail: "category",
                placeholder: type === "view" ? "-" : "차량 분류",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                required: true,
                defaultValue: car?.category?.categoryName,
            },
        },
        {
            name: ["carSwVer", "bmsVer", "swVerId"],
            label: String.bmsVersion,
            customSelector: {
                title: String.bmsVersion,
                typeDetail: "bmsVer",
                options: swVers
                    ?.filter((ver) => ver?.makerModel?.makerModelId === selectedModelId && ver.swType === "BMS")
                    ?.map((ver) => {
                        return { value: ver.swVerId, label: ver.swVer };
                    }),
                placeholder: type === "view" ? "-" : !selectedModelId ? "차량 모델 입력 후 선택 가능" : "BMS 버전",
                className: type === "view" ? "disabled-selector" : !selectedModelId ? "locked-selector" : "",
                disabled: type === "view",
                defaultValue: car?.carSwVer?.bmsVer?.swVer,
            },
        },
        {
            name: ["carSwVer", "vcuVer", "swVerId"],
            label: String.vcuVersion,
            customSelector: {
                title: String.vcuVersion,
                typeDetail: "vcuVer",
                options: swVers
                    ?.filter((ver) => ver?.makerModel?.makerModelId === selectedModelId && ver.swType === "VCU")
                    ?.map((ver) => {
                        return { value: ver.swVerId, label: ver.swVer };
                    }),
                placeholder: type === "view" ? "-" : !selectedModelId ? "차량 모델 입력 후 선택 가능" : "VCU 버전",
                className: type === "view" ? "disabled-selector" : !selectedModelId ? "locked-selector" : "",
                disabled: type === "view",
                defaultValue: car?.carSwVer?.vcuVer?.swVer,
            },
        },
        {
            name: ["runningSummary", "totalDrivingDistance"],
            label: String.totalRunningDistance,
            disabled: type !== "view",
        },
        {
            name: "description",
            label: String.description,
        },
    ];

    const { requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;

        const car = resultApiCarInfo.car;
        const summary = car?.runningSummary;
        const totalDrivingDistance =
            ((summary?.totalMileage || summary?.totalDrivingDistance)?.toFixed(1) || String.dash).replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/\.?0+$/, "") +
            String.kmDistanceUnit.replace("(", "").replace(")", "");

        form.setFieldsValue({
            ...car,
            customer: {
                ...car.customer,
                customerPhone: Utils.convertPhone(car?.customer?.customerPhone),
            },
            runningSummary: {
                ...summary,
                totalDrivingDistance,
            },
        });

        setSelectedMakerId(car?.makerModel?.maker?.makerId);
        setSelectedModelId(car?.makerModel?.makerModelId);
        setCar(car);
        setCustomerPhone(Utils.convertPhone(car?.customer?.customerPhone));
    }, [resultApiCarInfo]);

    const { requestApiCarRegister, resultApiCarRegister } = RequestCar.useRequestApiCarRegister();

    useEffect(() => {
        if (!resultApiCarRegister) return;

        onResult("register", resultApiCarRegister.car?.carId);
    }, [resultApiCarRegister]);

    const { requestApiCarUpdate, resultApiCarUpdate } = RequestCar.useRequestApiCarUpdate();

    useEffect(() => {
        if (!resultApiCarUpdate) return;

        onResult("edit");
    }, [resultApiCarUpdate]);

    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const { requestApiCategoryList, resultApiCategoryList } = RequestCategory.useRequestApiCategoryList();

    useEffect(() => {
        if (!resultApiCategoryList) return;

        setCategories(resultApiCategoryList.categories);
    }, [resultApiCategoryList]);

    const { requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { requestApiSwVerList, resultApiSwVerList } = RequestSwVer.useRequestApiSwVerList();

    useEffect(() => {
        if (!resultApiSwVerList) return;

        setSwVers(resultApiSwVerList.swVers);
    }, [resultApiSwVerList]);

    const onFinish = (type: viewType, value: any) => {
        const searchValue = {
            ...value,
            category: value?.category?.categoryId ? { categoryId: value.category.categoryId } : null,
            customer: value?.customer?.customerId
                ? { customerId: value.customer.customerId, customerPhone: value?.customer?.customerPhone?.replaceAll("-", "") }
                : null,
            carSwVer: {
                vcuVer: value?.carSwVer?.vcuVer?.swVerId ? { swVerId: value.carSwVer.vcuVer.swVerId } : null,
                bmsVer: value?.carSwVer?.bmsVer?.swVerId ? { swVerId: value.carSwVer.bmsVer.swVerId } : null,
            },
        };

        delete searchValue.runningSummary;
        type === "register" ? requestApiCarRegister(searchValue) : requestApiCarUpdate(carId, searchValue);
    };

    const onResult = (type: "register" | "edit" | "delete", id?: number) => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.car} ${typeText} 성공`, `${String.car} 정보를 ${typeText}하였습니다.`);
        setType("view");

        type === "register" ? navigate(Common.PAGE_MANAGE_CAR_DETAIL + "/" + id) : requestApiCarInfo(carId);
    };

    return (
        <>
            <CustomForm
                form={form}
                initialValues={car}
                type={type}
                contents={[type === "view" ? { name: "상세 정보", forms: contents } : { forms: contents }]}
                onFinish={onFinish}
                disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                onChangeType={(type) => {
                    if (type === "edit") {
                        navigate(Common.PAGE_MANAGE_CAR_UPDATE + "/" + car?.carId);
                    }
                    setType(type);
                }}
                onChangeSelectorValue={(key, value) => {
                    form.setFieldValue(key, value);

                    if (key.includes("customerId")) {
                        form.setFieldValue(
                            ["customer", "customerPhone"],
                            Utils.convertPhone(customers?.find((customer) => customer.customerId === value)?.customerPhone)
                        );
                    }
                }}
            />

            {car?.carNumberHistories && car?.carNumberHistories?.length > 0 && type === "view" && (
                <>
                    <Divider className="customDivider" />
                    <DataTable
                        title="변경이력"
                        disabledPagination
                        isMobile
                        rowKey={(row: TypeDTO.IssueDto) => row.issueId}
                        columns={columns}
                        dataSource={car?.carNumberHistories.sort((a, b) => new Date(b?.historyTime).valueOf() - new Date(a?.historyTime).valueOf())}
                    />
                </>
            )}
        </>
    );
};

export default TabCarInfo;
